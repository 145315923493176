import gql from 'graphql-tag';

import client from 'web/helpers/graphql_client';
import segmentAnalytics from '@analytics/client';

export const SET_NEWSLETTER_SUBSCRIBED = 'ACCOUNT_SET_NEWSLETTER_SUBSCRIBED';
export const SET_NEWSLETTER_SUBSCRIBED_ERROR = 'ACCOUNT_SET_NEWSLETTER_SUBSCRIBED_ERROR';
export const SET_SHOPPING_REMINDERS_SUBSCRIBED = 'ACCOUNT_SET_SHOPPING_REMINDERS_SUBSCRIBED';
export const SET_SMS_DELIVERY_REMINDERS_SUBSCRIBED =
  'ACCOUNT_SET_SMS_DELIVERY_REMINDERS_SUBSCRIBED';
export const SET_SUBSTITUTIONS = 'ACCOUNT_SET_SUBSTITUTIONS';
export const SET_SUBSTITUTIONS_ERROR = 'ACCOUNT_SET_SUBSTITUTIONS_ERROR';

export const actions = {
  setNewsletterSubscribed: (subscribed) => async (dispatch) => {
    try {
      let res;
      if (subscribed) {
        res = await subscribeUserToNewsletter();
      } else {
        res = await unsubscribeUserFromNewsletter();
      }
      if (res.data.result.success) {
        dispatch({
          type: SET_NEWSLETTER_SUBSCRIBED,
          subscribed,
        });
      } else {
        dispatch({
          type: SET_NEWSLETTER_SUBSCRIBED_ERROR,
          error: {
            type: 'GRAPHQL_ERROR',
          },
        });
      }
    } catch (error) {
      dispatch({
        type: SET_NEWSLETTER_SUBSCRIBED_ERROR,
        error,
      });
    }
  },
  setSMSDeliveryRemindersSubscribed: (value) => async (dispatch) => {
    // TODO(linalice): this function doesn't have error handling, unlike
    // setNewsletterSubscribed. It probably should.
    if (value) {
      await subscribeUserToSMSNotifications();
    } else {
      await unsubscribeUserFromSMSNotifications();
    }
    dispatch({
      type: SET_SMS_DELIVERY_REMINDERS_SUBSCRIBED,
      subscribed: value,
    });
  },
  setShoppingRemindersSubscribed: (value) => async (dispatch) => {
    // TODO(linalice): this function doesn't have error handling, unlike
    // setNewsletterSubscribed. It probably should.
    if (value) {
      await subscribeUserToPromptEmails();
    } else {
      await unsubscribeUserFromPromptEmails();
    }
    dispatch({
      type: SET_SHOPPING_REMINDERS_SUBSCRIBED,
      subscribed: value,
    });
  },
  setSubstitutions: (value) => async (dispatch) => {
    try {
      let res;
      let mutationName;

      if (value) {
        res = await allowSubstitutions();
        mutationName = 'allowSubstitutions';
      } else {
        res = await disallowSubstitutions();
        mutationName = 'disallowSubstitutions';
      }

      const {data} = res;
      if (typeof data[mutationName] !== 'undefined' && data[mutationName] !== null) {
        return dispatch({
          type: SET_SUBSTITUTIONS,
          substitute: value,
        });
      }
      dispatch({
        type: SET_SUBSTITUTIONS_ERROR,
        error: {
          type: 'GRAPHQL_ERROR',
        },
      });
    } catch {
      dispatch({
        type: SET_SUBSTITUTIONS_ERROR,
        error: {
          type: 'GRAPHQL_ERROR',
        },
      });
    }
  },
};

export function reducer(state = {}, action = {}) {
  switch (action.type) {
    case SET_NEWSLETTER_SUBSCRIBED:
      let newsletterUnsubscribedAt = null;
      if (!action.subscribed) {
        newsletterUnsubscribedAt = new Date(Date.now());
      }
      captureAccountUpdatedEvent(state, action.subscribed);
      return {
        ...state,
        newsletterError: null,
        user: {
          ...state.user,
          newsletter: action.subscribed,
          newsletterUnsubscribedAt,
        },
      };
    case SET_NEWSLETTER_SUBSCRIBED_ERROR:
      return {
        ...state,
        newsletterError: action.error,
        user: {...state.user, newsletter: false},
      };
    case SET_SMS_DELIVERY_REMINDERS_SUBSCRIBED:
      return {
        ...state,
        user: {
          ...state.user,
          sms: {
            ...state.user.sms,
            subscribed: action.subscribed,
          },
        },
      };
    case SET_SHOPPING_REMINDERS_SUBSCRIBED:
      return {
        ...state,
        user: {
          ...state.user,
          promptEmails: {
            ...state.user.promptEmails,
            subscribed: action.subscribed,
          },
        },
      };
    case SET_SUBSTITUTIONS:
      return {
        ...state,
        substitutionError: null,
        user: {...state.user, substitutions: action.substitute},
      };
    case SET_SUBSTITUTIONS_ERROR:
      return {
        ...state,
        substitutionError: action.error,
        user: {...state.user, substitutions: null},
      };
    default:
  }
  return state;
}

async function subscribeUserToSMSNotifications() {
  return client.mutate({
    mutation: gql`
      mutation SubscribeUserToSmsNotifications {
        result: subscribeUserToSMSNotifications {
          success
        }
      }
    `,
  });
}

async function unsubscribeUserFromSMSNotifications() {
  return client.mutate({
    mutation: gql`
      mutation UnsubscribeUserFromSmsNotifications {
        result: unsubscribeUserFromSMSNotifications {
          success
        }
      }
    `,
  });
}

async function subscribeUserToNewsletter() {
  return client.mutate({
    mutation: gql`
      mutation subscribeUserToNewsletter {
        result: subscribeUserToNewsletter {
          success
        }
      }
    `,
  });
}

async function unsubscribeUserFromNewsletter() {
  return client.mutate({
    mutation: gql`
      mutation UnsubscribeUserFromNewsletter {
        result: unsubscribeUserFromNewsletter {
          success
        }
      }
    `,
  });
}

async function subscribeUserToPromptEmails() {
  return client.mutate({
    mutation: gql`
      mutation SubscribeUserToPromptEmails {
        result: subscribeUserToPromptEmails {
          success
        }
      }
    `,
  });
}

async function unsubscribeUserFromPromptEmails() {
  return client.mutate({
    mutation: gql`
      mutation UnsubscribeUserFromPromptEmails {
        result: unsubscribeUserFromPromptEmails {
          success
        }
      }
    `,
  });
}

async function allowSubstitutions() {
  return client.mutate({
    mutation: gql`
      mutation AllowSubstitutions {
        allowSubstitutions {
          success
        }
      }
    `,
  });
}

async function disallowSubstitutions() {
  return client.mutate({
    mutation: gql`
      mutation DisallowSubstitutions {
        disallowSubstitutions {
          success
        }
      }
    `,
  });
}

function captureAccountUpdatedEvent(state, subscribedToNewsletter) {
  const {user} = state;
  const accountUpdatedProps = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    loggedIn: true,
    name: `${user.firstName} ${user.lastName ?? ''}`,
    masquerading: user.masquerading,
    newsAndInspirations: subscribedToNewsletter,
    paymentMethod: Boolean(user.active_card),
    phoneNumber: user.phone,
    shoppingReminders: user.promptEmails.subscribed,
    smsReminders: user.sms.subscribed,
  };
  segmentAnalytics.track('accountUpdated', accountUpdatedProps);
}
